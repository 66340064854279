.character {
  font-size: 20px;
  border-radius: 4px;
  background-color: transparent;
  border-color: #bdbdbd;
}

.character--selected {
  border-color: #0277bd;
  outline-color: #0277bd;
}

.character--selected--disabled {
  outline: 0;
  color: black;
}
