@font-face {
  font-family: 'LeckerliOne';
  src: local('LeckerliOne'),
    url('./fonts/LeckerliOne-Regular.ttf') format('truetype');
}

html,
body,
#root {
  height: 100%;
}

:root {
  --ion-color-primary: #0277bd;
  --ion-color-secondary: #fff;
}

.safe-area-top,
.MuiDialog-paperFullScreen,
.MuiDrawer-paper {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-left: env(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-right: env(safe-area-inset-right);
}

.safe-area-bottom,
.MuiDialog-paperFullScreen,
.MuiDrawer-paper {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

ion-content {
  --background: #015384;
}

ion-header * {
  box-sizing: border-box;
}
